import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button } from '@core';
import { ImageCore, Text } from '@components';
import { ErrorText } from '@containers/checkout/components';
import klarnaIcon from '@images/checkout/klarna-method.svg';
import { klarnaTheme } from '@theme';

import Summary from '../components/summary';

const KlarnaButtonImage = () => (
  <ImageCore mx={8} src={klarnaIcon} alt="klarna" height={18} width={80} />
);

const KlarnaPayment = ({
  selectedCard,
  klarnaError,
  stripe,
  purchaseCompleted,
  isLoading,
  emailError,
  submitHandler,
}) => {
  const { t } = useTranslation();

  return (
    <Summary selectedCard={selectedCard}>
      <Box mt={24} mb={16}>
        <ThemeProvider theme={klarnaTheme}>
          <Button
            disabled={isLoading || emailError || !stripe || purchaseCompleted}
            fullWidth
            type="button"
            size="large"
            onClick={submitHandler}
          >
            {isLoading ? (
              t('checkout:cardPayment.processing')
            ) : (
              <Text.Body1Strong as="span" color="#000000" lineHeight="22px">
                <Trans
                  i18nKey="checkout:buyWithPaymentMethod"
                  components={[<KlarnaButtonImage key={0} />]}
                />
              </Text.Body1Strong>
            )}
          </Button>
        </ThemeProvider>
        {(emailError || klarnaError.message) && (
          <ErrorText>{emailError || klarnaError.message}</ErrorText>
        )}
      </Box>
    </Summary>
  );
};

export default KlarnaPayment;
