import React, { useMemo, useState } from 'react';
import GooglePayButton from '@google-pay/button-react';
import { useTranslation } from 'react-i18next';

import { GooglePayButtonPlaceholder, GooglePayButtonWrapper } from './google-pay-components';
import { Loader } from '@components';
import { ErrorText } from '@containers/checkout/components';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

import Summary from '../components/summary';

const GooglePay = ({
  selectedCard,
  totalPrice,
  executePayment,
  stripe,
  triggerEmailError,
  isEmailValid,
  emailError,
  isLoading,
  trackSubmitClick,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [buttonLoadError, setButtonLoadError] = useState();

  const paymentRequest = useMemo(
    () => ({
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'stripe',
              'stripe:version': '2020-03-02',
              'stripe:publishableKey': process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: process.env.GOOGLE_PAY_MERCHANT_ID,
        merchantName: process.env.GOOGLE_PAY_MERCHANT_NAME,
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: `${totalPrice}`,
        currencyCode: selectedCard.currency,
        countryCode: 'US',
      },
    }),
    [totalPrice, selectedCard.currency]
  );

  const createPaymentMethod = async (paymentData) => {
    isLoading.set(true);

    const { token } = paymentData.paymentMethodData.tokenizationData;
    const paymentMethodData = {
      type: 'card',
      card: { token: JSON.parse(token).id },
    };
    const { error, paymentMethod } = await stripe.createPaymentMethod(paymentMethodData);

    if (error) {
      trackCustomGA4Event({
        eventName: GA4_EVENTS.purchaseFail,
        params: {
          type: error?.type,
          code: error?.code,
          decline_code: error?.decline_code,
          message: error?.message,
        },
      });
    }

    if (paymentMethod) {
      executePayment(paymentMethod);
    }
  };

  const handleReadyToPayChange = (state) => {
    if (!state.isReadyToPay) {
      setButtonLoadError(t('checkout:errors.somethingWentWrong'));
    } else {
      if (buttonLoadError) {
        setButtonLoadError(undefined);
      }
    }
  };

  return (
    <div>
      <Summary selectedCard={selectedCard}>
        <React.Fragment>
          {buttonLoadError ? (
            <ErrorText>{buttonLoadError}</ErrorText>
          ) : (
            <React.Fragment>
              <GooglePayButtonPlaceholder>
                {isLoading.get ? (
                  <Loader isLoading={isLoading.get} />
                ) : (
                  <GooglePayButtonWrapper>
                    <GooglePayButton
                      buttonLocale={language}
                      environment={process.env.GOOGLE_PAY_BUTTON_ENV}
                      paymentRequest={paymentRequest}
                      onReadyToPayChange={handleReadyToPayChange}
                      onLoadPaymentData={(paymentData) => createPaymentMethod(paymentData)}
                      onClick={
                        !isEmailValid
                          ? (event) => {
                              triggerEmailError();
                              event.preventDefault(); // prevents opening GPay payment window
                            }
                          : trackSubmitClick
                      }
                    />
                  </GooglePayButtonWrapper>
                )}
              </GooglePayButtonPlaceholder>
              {emailError && <ErrorText>{emailError}</ErrorText>}
            </React.Fragment>
          )}
        </React.Fragment>
      </Summary>
    </div>
  );
};

export default GooglePay;
