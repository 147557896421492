import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { Button } from '@core';
import { ImageCore, Text } from '@components';
import { sofortTheme } from '@theme';
import { ErrorText } from '@containers/checkout/components';
import sofortSvg from '@images/checkout/sofort-no-frame.svg';

import Summary from '../components/summary';

const SofortButtonImage = () => (
  <ImageCore src={sofortSvg} alt="Sofort" height={24} width={74} verticalAlign="middle" />
);

const SofortPayment = ({
  selectedCard,
  stripe,
  sofortError,
  isLoading,
  emailError,
  submitHandler,
}) => {
  const { t } = useTranslation();

  return (
    <Summary selectedCard={selectedCard}>
      <ThemeProvider theme={sofortTheme}>
        <Button
          fullWidth={true}
          type="button"
          onClick={submitHandler}
          size="large"
          disabled={!stripe || isLoading || emailError}
        >
          {isLoading ? (
            t('checkout:cardPayment.processing')
          ) : (
            <Text.Body1Strong as="span" color="white" lineHeight="22px">
              <Trans
                i18nKey="checkout:buyWithPaymentMethod"
                components={[<SofortButtonImage key={0} />]}
              />
            </Text.Body1Strong>
          )}
        </Button>
      </ThemeProvider>
      {(emailError || sofortError.message) && (
        <ErrorText>{emailError || sofortError.message}</ErrorText>
      )}
    </Summary>
  );
};

export default SofortPayment;
