import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { ImageCore, Text } from '@components';
import { Shield } from '@components/icon';
import { Box, Field, Form, Grid, Input } from '@core';
import { trackCustomGA4Event, trackPageView } from '@shared/google-utils';
import { ERROR_TYPES, GA4_EVENTS } from '@shared/constants/google';
import { ONE_MONTH_PLAN, PAYMENT_METHODS } from '@shared/constants/checkout';
import atlasLogo from '@images/logos/atlas-logo.svg';
import applePayIcon from '@images/checkout/apple-pay-method.svg';
import qiwiIcon from '@images/checkout/qiwi-method.svg';
import supportedCards from '@images/checkout/credit-cards-method.svg';
import googlePayIcon from '@images/checkout/google-pay-method.svg';
import paypalIcon from '@images/checkout/paypal-method.svg';
import cryptoIcon from '@images/checkout/crypto-method.svg';
import klarnaIcon from '@images/checkout/klarna-method.svg';
import idealIcon from '@images/checkout/ideal-method.svg';
import sofortIcon from '@images/checkout/sofort-method.svg';
import kakaoPayIcon from '@images/checkout/kakaopay-method.svg';

import { checkoutFormController, executePayment, handleSubmit } from './utils';
import {
  ApplePay,
  CardPayment,
  CryptoPayment,
  GooglePay,
  IDealPayment,
  KakaoPayment,
  KlarnaPayment,
  MethodContainer,
  OrderSummary,
  Paypal,
  Qiwi,
  SofortPayment,
} from './components';
import { removeUUID } from '@shared/local-storage-tracking';

const {
  APPLE_PAY,
  CARD_PAYMENT,
  GOOGLE_PAY,
  PAYPAL,
  QIWI,
  CRYPTO,
  KAKAOPAY,
  KLARNA,
  IDEAL,
  SOFORT,
} = PAYMENT_METHODS;

const scrollToEmailStep = () => scrollTo('#email-input');

const Payment = ({
  setStripe,
  isLoading: isLoadingPlans,
  selectedCard,
  setPurchaseCompleted,
  purchaseCompleted,
  setHasPurchaseFailed,
  coupon,
  successPageSlug,
  errorPageSlug,
  selectedAddon,
  orderData,
  onAddonSelect,
  onAddonRemove,
  // TODO move handle of onClick by value to form
  onClickPayment,
  showQiwiPaymentMethod,
  showKlarnaPaymentMethod,
  showIDealPaymentMethod,
  showSofortPaymentMethod,
  showKakaoPaymentMethod,
  hidePaypal,
  parsedEmail,
  setEmail,
}) => {
  const [cardElementError, setCardElementError] = useState({});
  const [klarnaError, setKlarnaError] = useState({});
  const [iDealElementError, setIDealElementError] = useState({});
  const [sofortError, setSofortError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [isCompleted, setIsCompleted] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const stripe = useStripe();
  const elements = useElements();

  const totalPrice = orderData.totalPriceAfterTax;

  const trackSubmitClick = (errors) => {
    trackCustomGA4Event({
      eventName: GA4_EVENTS.purchaseClick(selectedPaymentMethod.toLowerCase()),
      params: {
        plan_id: selectedCard.identifier,
        currency: selectedCard.currency,
        error: JSON.stringify(errors),
      },
    });
  };

  const handlePaymentMethodClick = (paymentMethod) => {
    if (!isSelectedMethod(paymentMethod)) {
      trackPageView('/get/virtual/payment/');
      trackCustomGA4Event({
        eventName: GA4_EVENTS.paymentMethodClick,
        params: { value: paymentMethod },
      });
    }

    setSelectedPaymentMethod(isSelectedMethod(paymentMethod) ? '' : paymentMethod);
  };

  const isSelectedMethod = (method) => {
    return selectedPaymentMethod === method;
  };

  useEffect(() => {
    if (!stripe) {
      setStripe(loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY, { locale: 'en' }));
    }
  }, [setStripe, stripe]);

  useEffect(() => {
    if (isCompleted) {
      removeUUID();
    }
    setPurchaseCompleted(isCompleted);
  }, [isCompleted, setPurchaseCompleted]);

  useEffect(() => {
    if (hasFailed) {
      removeUUID();
    }
    setHasPurchaseFailed(hasFailed);
  }, [hasFailed, setHasPurchaseFailed]);

  return (
    <Box maxWidth={1080} mx="auto" mt={24}>
      <Form
        {...checkoutFormController({
          t,
          email: parsedEmail || orderData.email,
          selectedPaymentMethod,
        })}
      >
        {({ values, errors, validateForm, setTouched, setFieldTouched, touched }) => {
          const triggerEmailError = () => {
            setFieldTouched('email', true, true).then((errors) => {
              trackSubmitClick({ email: errors?.email });
              scrollToEmailStep();
            });
          };
          return (
            <Grid.Container data-section-id="CheckoutForm" px={{ _: 8, lg: 0 }}>
              <Grid.Row>
                <Grid.Col size={{ _: 12, lg: 7 }} px={8}>
                  <Text.Subheading2>
                    {t('checkout:paymentSection.createAccount.title')}
                  </Text.Subheading2>

                  <Box
                    mt={24}
                    mb={32}
                    px={{ _: 16, md: 40 }}
                    py={{ _: 24, md: 32 }}
                    border="2px solid"
                    borderColor="primary"
                    borderRadius="md"
                  >
                    <Text.Body3>{t('checkout:paymentSection.createAccount.emailInfo')}</Text.Body3>

                    <Box id="email-input" mt={16}>
                      <Field
                        data-testid="checkout-email-input"
                        id="email"
                        name="email"
                        label={t('checkout:inputFields.email.label')}
                        placeholder={t('checkout:inputFields.email.placeholder')}
                        component={Input.Text}
                        onFocus={() => {
                          trackPageView('/get/virtual/contacts/');
                          trackCustomGA4Event({ eventName: GA4_EVENTS.emailFieldFocus });
                        }}
                        onBlur={() => {
                          if (values.email && !errors.email) {
                            setEmail(values.email);
                          }
                          trackCustomGA4Event({ eventName: GA4_EVENTS.emailFieldBlur });
                        }}
                      />
                    </Box>

                    <Text.Body3 mt={16}>
                      {t('checkout:paymentSection.createAccount.privacy')}
                    </Text.Body3>
                  </Box>
                </Grid.Col>
                <Grid.Col size={{ _: 12, lg: 5 }} px={8}>
                  {!isLoadingPlans && (
                    <React.Fragment>
                      <Text.Body1Strong>{t('checkout:paymentSummary.title')}</Text.Body1Strong>
                      <Box position={{ lg: 'relative' }}>
                        <Box
                          position={{ lg: 'absolute' }}
                          top={{ lg: 0 }}
                          left={{ lg: 0 }}
                          /* current minimal height of page (minus navbar and step selector area, with 4 collapsed payment methods)
                           * this has to be set so this container doesn't overflow page in any scenario
                           */
                          height={{ lg: 1150 }}
                          width={{ lg: '100%' }}
                        >
                          <Box position={{ lg: 'sticky' }} top={{ lg: 16 }} mb={{ _: 32, lg: 0 }}>
                            <Box
                              mt={24}
                              mb={8}
                              px={{ _: 8, md: 24 }}
                              py={{ _: 16, md: 32 }}
                              border="2px solid"
                              borderColor="primary_20"
                              borderRadius="md"
                            >
                              <Box pl={{ _: 8, md: 16 }}>
                                <ImageCore src={atlasLogo} alt="Atlas VPN" />
                              </Box>
                              <OrderSummary
                                selectedCard={selectedCard}
                                orderData={orderData}
                                selectedAddon={selectedAddon}
                                onAddonSelect={onAddonSelect}
                                onAddonRemove={onAddonRemove}
                                t={t}
                              />
                            </Box>

                            <Box display="flex" alignItems="center" pl={{ lg: 40 }} columnGap={14}>
                              <Box display="flex" alignItems="center">
                                <Shield height={28} width="auto" />
                              </Box>
                              {selectedCard.subscriptionPeriod === ONE_MONTH_PLAN ? (
                                <Text.Body3Strong>
                                  {t('checkout:moneyBackStamp14')}
                                </Text.Body3Strong>
                              ) : (
                                <Text.Body3Strong>{t('checkout:moneyBackStamp')}</Text.Body3Strong>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </React.Fragment>
                  )}
                </Grid.Col>
                <Grid.Col size={{ _: 12, lg: 7 }} px={8}>
                  <Text.Subheading2>{t('checkout:paymentSection.selectPayment')}</Text.Subheading2>
                  <ApplePay
                    // MethodContainer component is inside payment method component as all of it is dynamically rendered
                    methodContainerProps={{
                      name: 'ApplePay',
                      logo: { src: applePayIcon, alt: 'ApplePay' },
                      dataTestId: 'applepay-container',
                      isSelected: isSelectedMethod(APPLE_PAY),
                      onClick: () => {
                        onClickPayment(APPLE_PAY);
                        handlePaymentMethodClick(APPLE_PAY);
                      },
                    }}
                    stripe={stripe}
                    selectedCard={selectedCard}
                    orderData={orderData}
                    executePayment={(paymentMethod) =>
                      executePayment({
                        paymentMethod,
                        formValues: values,
                        executeRecaptcha,
                        t,
                        selectedCard,
                        totalPrice,
                        coupon,
                        stripe,
                        selectedPaymentMethod,
                        setIsCompleted,
                        setHasFailed,
                        setIsLoading,
                      })
                    }
                    email={values.email}
                    emailError={touched.email && errors.email}
                    triggerEmailError={triggerEmailError}
                    isLoading={isLoading || isLoadingPlans}
                    trackSubmitClick={trackSubmitClick}
                  />
                  {showKakaoPaymentMethod && (
                    <MethodContainer
                      name="KakaoPay"
                      logo={{ src: kakaoPayIcon, alt: 'KakaoPay' }}
                      dataTestId="kakaopay-container"
                      isSelected={isSelectedMethod(KAKAOPAY)}
                      onClick={() => {
                        onClickPayment(KAKAOPAY);
                        handlePaymentMethodClick(KAKAOPAY);
                      }}
                    >
                      <KakaoPayment
                        selectedCard={selectedCard}
                        triggerEmailError={triggerEmailError}
                        emailError={touched.email && errors.email}
                        email={values.email}
                        onErrorCallback={() => {
                          setHasFailed(true);
                        }}
                        successPageSlug={successPageSlug}
                        errorPageSlug={errorPageSlug}
                        trackSubmitClick={trackSubmitClick}
                      />
                    </MethodContainer>
                  )}
                  {/*iDeal*/}
                  {showIDealPaymentMethod && (
                    <MethodContainer
                      name={t('checkout:paymentMethods.iDeal')}
                      logo={{
                        src: idealIcon,
                        alt: 'iDEAL',
                      }}
                      dataTestId="ideal-container"
                      isSelected={isSelectedMethod(IDEAL)}
                      onClick={() => {
                        onClickPayment(IDEAL);
                        handlePaymentMethodClick(IDEAL);
                      }}
                    >
                      <IDealPayment
                        selectedCard={selectedCard}
                        iDealElementError={iDealElementError}
                        isLoading={isLoading}
                        emailError={touched.email && errors.email}
                        stripe={stripe}
                        setIDealElementError={setIDealElementError}
                        submitHandler={() =>
                          handleSubmit({
                            coupon,
                            elements,
                            executeRecaptcha,
                            setPaymentMethodError: setIDealElementError,
                            scrollToEmailStep,
                            selectedCard,
                            totalPrice,
                            selectedPaymentMethod,
                            setHasFailed,
                            setIsCompleted,
                            setIsLoading,
                            setTouched,
                            stripe,
                            t,
                            trackSubmitClick,
                            validateForm,
                            values,
                            language,
                          })
                        }
                      />
                    </MethodContainer>
                  )}

                  {/*Qiwi*/}
                  {showQiwiPaymentMethod && (
                    <MethodContainer
                      name={t('checkout:paymentMethods.qiwi')}
                      logo={{
                        src: qiwiIcon,
                        alt: 'qiwi',
                      }}
                      dataTestId="qiwi-container"
                      isSelected={isSelectedMethod(QIWI)}
                      onClick={() => {
                        onClickPayment(QIWI);
                        handlePaymentMethodClick(QIWI);
                      }}
                    >
                      <Qiwi
                        selectedCard={selectedCard}
                        isLoading={isLoading}
                        triggerEmailError={triggerEmailError}
                        emailError={touched.email && errors.email}
                        email={values.email}
                        onErrorCallback={() => {
                          setHasFailed(true);
                        }}
                        trackSubmitClick={trackSubmitClick}
                        successPageSlug={successPageSlug}
                        errorPageSlug={errorPageSlug}
                      />
                    </MethodContainer>
                  )}
                  {/*Stripe*/}
                  <MethodContainer
                    name={t('checkout:paymentMethods.creditCards')}
                    logo={{
                      src: supportedCards,
                      alt: 'supported-cards',
                    }}
                    dataTestId="credit-card-container"
                    isSelected={isSelectedMethod(CARD_PAYMENT)}
                    onClick={() => {
                      onClickPayment(CARD_PAYMENT);
                      handlePaymentMethodClick(CARD_PAYMENT);
                    }}
                  >
                    <CardPayment
                      selectedCard={selectedCard}
                      cardElementError={cardElementError}
                      setCardElementError={setCardElementError}
                      stripe={stripe}
                      purchaseCompleted={purchaseCompleted}
                      isLoading={isLoading}
                      submitHandler={() =>
                        handleSubmit({
                          coupon,
                          elements,
                          executeRecaptcha,
                          scrollToEmailStep,
                          selectedCard,
                          totalPrice,
                          selectedPaymentMethod,
                          setPaymentMethodError: setCardElementError,
                          setHasFailed,
                          setIsCompleted,
                          setIsLoading,
                          setTouched,
                          stripe,
                          t,
                          trackSubmitClick,
                          validateForm,
                          values,
                        })
                      }
                    />
                  </MethodContainer>

                  <MethodContainer
                    name={t('checkout:paymentMethods.googlePay')}
                    logo={{
                      src: googlePayIcon,
                      alt: 'google-pay',
                    }}
                    dataTestId="google-container"
                    isSelected={isSelectedMethod(GOOGLE_PAY)}
                    onClick={() => {
                      onClickPayment(GOOGLE_PAY);
                      handlePaymentMethodClick(GOOGLE_PAY);
                    }}
                  >
                    <GooglePay
                      selectedCard={selectedCard}
                      totalPrice={totalPrice}
                      executePayment={(paymentMethod) =>
                        executePayment({
                          paymentMethod,
                          formValues: values,
                          executeRecaptcha,
                          t,
                          selectedCard,
                          totalPrice,
                          coupon,
                          stripe,
                          selectedPaymentMethod,
                          setIsCompleted,
                          setHasFailed,
                          setIsLoading,
                        })
                      }
                      stripe={stripe}
                      isEmailValid={!!(values.email && !errors.email)}
                      triggerEmailError={triggerEmailError}
                      trackSubmitClick={trackSubmitClick}
                      emailError={touched.email && errors.email}
                      isLoading={{ get: isLoading, set: setIsLoading }}
                    />
                  </MethodContainer>
                  {!hidePaypal && (
                    <MethodContainer
                      name={t('checkout:paymentMethods.payPal')}
                      logo={{
                        src: paypalIcon,
                        alt: 'paypal',
                      }}
                      dataTestId="paypal-container"
                      isSelected={isSelectedMethod(PAYPAL)}
                      onClick={() => {
                        onClickPayment(PAYPAL);
                        handlePaymentMethodClick(PAYPAL);
                      }}
                    >
                      <Paypal
                        buttonsContainerId="checkout-form-paypal"
                        isLoading={isLoadingPlans}
                        selectedCard={selectedCard}
                        enteredEmail={values.email}
                        isEmailValid={!!(values.email && !errors.email)}
                        emailError={touched.email && errors.email}
                        triggerEmailError={triggerEmailError}
                        onApproveCallback={() => {
                          setIsCompleted(true);
                        }}
                        onErrorCallback={() => {
                          trackCustomGA4Event({
                            eventName: GA4_EVENTS.purchaseFail,
                            params: {
                              type: ERROR_TYPES.payPal,
                            },
                          });
                          setHasFailed(true);
                        }}
                      />
                    </MethodContainer>
                  )}
                  {/* SOFORT */}
                  {showSofortPaymentMethod && (
                    <MethodContainer
                      name={t('checkout:paymentMethods.sofort')}
                      logo={{
                        src: sofortIcon,
                        alt: 'Sofort',
                      }}
                      dataTestId="sofort-container"
                      isSelected={isSelectedMethod(SOFORT)}
                      onClick={() => {
                        onClickPayment(SOFORT);
                        handlePaymentMethodClick(SOFORT);
                      }}
                    >
                      <SofortPayment
                        selectedCard={selectedCard}
                        isLoading={isLoading}
                        emailError={touched.email && errors.email}
                        stripe={stripe}
                        sofortError={sofortError}
                        submitHandler={() =>
                          handleSubmit({
                            coupon,
                            elements,
                            executeRecaptcha,
                            scrollToEmailStep,
                            selectedCard,
                            totalPrice,
                            selectedPaymentMethod,
                            setPaymentMethodError: setSofortError,
                            setHasFailed,
                            setIsCompleted,
                            setIsLoading,
                            setTouched,
                            stripe,
                            t,
                            trackSubmitClick,
                            validateForm,
                            values,
                            language,
                          })
                        }
                      />
                    </MethodContainer>
                  )}
                  <MethodContainer
                    name={t('checkout:paymentMethods.crypto')}
                    logo={{ src: cryptoIcon, alt: 'crypto' }}
                    dataTestId="crypto-container"
                    isSelected={isSelectedMethod(CRYPTO)}
                    onClick={() => {
                      onClickPayment(CRYPTO);
                      handlePaymentMethodClick(CRYPTO);
                    }}
                  >
                    <CryptoPayment
                      selectedCard={selectedCard}
                      triggerEmailError={triggerEmailError}
                      emailError={touched.email && errors.email}
                      email={values.email}
                      onErrorCallback={() => {
                        setHasFailed(true);
                      }}
                      successPageSlug={successPageSlug}
                      errorPageSlug={errorPageSlug}
                      trackSubmitClick={trackSubmitClick}
                    />
                  </MethodContainer>
                  {showKlarnaPaymentMethod && (
                    <MethodContainer
                      name={t('checkout:paymentMethods.klarna')}
                      logo={{ src: klarnaIcon, alt: 'klarna' }}
                      dataTestId="klarna-container"
                      isSelected={isSelectedMethod(KLARNA)}
                      onClick={() => {
                        onClickPayment(KLARNA);
                        handlePaymentMethodClick(KLARNA);
                      }}
                    >
                      <KlarnaPayment
                        selectedCard={selectedCard}
                        klarnaError={klarnaError}
                        stripe={stripe}
                        purchaseCompleted={purchaseCompleted}
                        isLoading={isLoading}
                        emailError={touched.email && errors.email}
                        submitHandler={() =>
                          handleSubmit({
                            coupon,
                            elements,
                            executeRecaptcha,
                            scrollToEmailStep,
                            selectedCard,
                            totalPrice,
                            selectedPaymentMethod,
                            setPaymentMethodError: setKlarnaError,
                            setHasFailed,
                            setIsCompleted,
                            setIsLoading,
                            setTouched,
                            stripe,
                            t,
                            trackSubmitClick,
                            validateForm,
                            values,
                            language,
                          })
                        }
                      />
                    </MethodContainer>
                  )}
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>
          );
        }}
      </Form>
    </Box>
  );
};

export default Payment;
