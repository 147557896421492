import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardElement } from '@stripe/react-stripe-js';

import { CheckoutInput } from '@containers/checkout/components';
import { cardOptions } from '@containers/checkout/utils/card-options';
import { Text } from '@components';
import { Box, Button, Field, Input } from '@core';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

import Summary from '../components/summary';

const CardPayment = ({
  cardElementError,
  setCardElementError,
  selectedCard,
  stripe,
  purchaseCompleted,
  isLoading,
  submitHandler,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Text.Body2Strong pb={16}>{t('checkout:cardPayment.title')}</Text.Body2Strong>
      <Field
        id="name"
        name="name"
        label={t('checkout:inputFields.name.label')}
        placeholder={t('checkout:inputFields.name.placeholder')}
        component={Input.Text}
      />
      <Box my={16}>
        <CheckoutInput
          data-testid="card-input-wrapper"
          label={t('checkout:inputFields.cardDetails.label')}
          as="div"
          error={cardElementError && cardElementError.message}
        >
          <CardElement
            onChange={(event) => {
              setCardElementError(event.error);
            }}
            onFocus={() => {
              trackCustomGA4Event({
                eventName: GA4_EVENTS.paymentDetailsFocus,
              });
            }}
            onBlur={() => {
              trackCustomGA4Event({
                eventName: GA4_EVENTS.paymentDetailsFocusOut,
                params: {
                  error_title: cardElementError && cardElementError.message,
                },
              });
            }}
            options={cardOptions}
          />
        </CheckoutInput>
      </Box>

      <Summary selectedCard={selectedCard}>
        <Box mt={24} mb={16}>
          <Button
            fullWidth={true}
            type="button"
            onClick={submitHandler}
            size="large"
            disabled={!stripe || purchaseCompleted || isLoading}
          >
            {isLoading
              ? t('checkout:cardPayment.processing')
              : t('checkout:cardPayment.completePurchase')}
          </Button>
        </Box>
      </Summary>
    </Box>
  );
};

export default CardPayment;
