import queryString from 'query-string';

import { paymentErrorHandler } from '@shared/checkout-error-helpers';
import { getTrackingParamsForPayment } from '@containers/checkout/utils/local-storage';
import { trackCustomGA4Event } from '@shared/google-utils';
import { ERROR_TYPES, GA4_EVENTS } from '@shared/constants/google';
import {
  createPurchaseEventData,
  createPurchaseEventItem,
  firePurchaseEvent,
} from '@containers/checkout/utils/tracking';
import { PAYMENT_METHODS } from '@shared/constants/checkout';
import { getUUID, removeUUID } from '@shared/local-storage-tracking';
import { updateOrderAndPay } from '@api/order';

const confirmStripePayment = ({
  clientSecret,
  selectedCard,
  totalPrice,
  selectedPaymentMethod,
  setHasFailed,
  setIsLoading,
  stripe,
  paymentMethodId,
  language,
}) => {
  // passing extra parameters to returned /payment page to fire tracking there with analogical data as we do in confirmCardPayment
  const parameters = {
    payment_method: selectedPaymentMethod,
    plan_id: selectedCard.identifier,
    plan_title: selectedCard.title,
    price: totalPrice,
    language,
  };
  const confirmPaymentFunctions = {
    IDEAL: stripe.confirmIdealPayment,
    KLARNA: stripe.confirmKlarnaPayment,
    SOFORT: stripe.confirmSofortPayment,
  };
  // Clearing uuid before 3rd party modal opens. To protect offer from mutations after 3rd party verification is done.
  removeUUID();

  confirmPaymentFunctions[selectedPaymentMethod](clientSecret, {
    payment_method: paymentMethodId,
    return_url: `${window.location.origin}/payment?${queryString.stringify(parameters)}`,
  }).then((result) => {
    if (result.error) {
      const { error } = result;
      setHasFailed(true);

      trackCustomGA4Event({
        eventName: GA4_EVENTS.purchaseFail,
        params: {
          type: error?.type,
          code: error?.code,
          decline_code: error?.decline_code,
          message: error?.message,
        },
      });
    }
    setIsLoading(false);
  });
};

const confirmCardPayment = ({
  clientSecret,
  purchaseEventItem,
  selectedCard,
  selectedPaymentMethod,
  setHasFailed,
  setIsCompleted,
  setIsLoading,
  stripe,
}) => {
  stripe.confirmCardPayment(clientSecret).then((result) => {
    if (result.error) {
      const { error } = result;
      setHasFailed(true);
      trackCustomGA4Event({
        eventName: GA4_EVENTS.purchaseFail,
        params: {
          type: error?.type,
          code: error?.code,
          decline_code: error?.decline_code,
          message: error?.message,
        },
      });
    } else {
      firePurchaseEvent(
        createPurchaseEventData(
          result.paymentIntent.id,
          result.paymentIntent.currency === 'jpy'
            ? result.paymentIntent.amount
            : result.paymentIntent.amount / 100,
          result.paymentIntent.currency,
          selectedPaymentMethod,
          purchaseEventItem
        )
      );
      trackCustomGA4Event({
        eventName: GA4_EVENTS.successfulPurchase,
        params: {
          currency: result.paymentIntent.currency,
          plan_id: selectedCard.identifier,
          payment_method: selectedPaymentMethod,
        },
      });
      setIsCompleted(true);
    }
    setIsLoading(false);
  });
};

export const executePayment = async ({
  coupon,
  executeRecaptcha,
  formValues,
  paymentMethod,
  selectedCard,
  totalPrice,
  selectedPaymentMethod,
  setHasFailed,
  setIsCompleted,
  setIsLoading,
  stripe,
  t,
  language,
}) => {
  if (!executeRecaptcha) {
    paymentErrorHandler({ t });
    return;
  }

  const recaptchaToken = await executeRecaptcha('payment');

  const data = {
    ...getTrackingParamsForPayment(),
    name: formValues.name,
    payment_method_id: paymentMethod.id,
    postal_code: paymentMethod.billing_details.address.postal_code,
    token: recaptchaToken,
  };

  updateOrderAndPay({ uniqueId: getUUID(), metadata: data })
    .then((response) => {
      if (response.error) {
        throw response.error;
      }

      if (response.status === 'requires_confirmation') {
        if (
          [
            PAYMENT_METHODS.CARD_PAYMENT,
            PAYMENT_METHODS.GOOGLE_PAY,
            PAYMENT_METHODS.APPLE_PAY,
          ].includes(selectedPaymentMethod)
        ) {
          confirmCardPayment({
            clientSecret: response.client_secret,
            purchaseEventItem: createPurchaseEventItem(
              selectedCard.identifier,
              selectedCard.title,
              1,
              totalPrice
            ),
            stripe,
            setHasFailed,
            selectedPaymentMethod,
            selectedCard,
            setIsCompleted,
            setIsLoading,
          });
        } else {
          confirmStripePayment({
            clientSecret: response.client_secret,
            stripe,
            setHasFailed,
            selectedPaymentMethod,
            selectedCard,
            totalPrice,
            setIsLoading,
            paymentMethodId: paymentMethod.id,
            language,
          });
        }
      }
    })
    .catch((error) => {
      setIsLoading(false);
      const errorData = error?.response?.data?.error;
      trackCustomGA4Event({
        eventName: GA4_EVENTS.purchaseFail,
        params: {
          type: ERROR_TYPES.stripePay,
          message: errorData?.message,
          code: errorData?.code,
        },
      });
      paymentErrorHandler({ error, t });
    });
};
