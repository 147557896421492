import React from 'react';
import { Trans } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';

import { Box, Button, Grid } from '@core';
import { Divider, Icon, Modal, Text } from '@components';
import { CrossedSpan } from '@containers/checkout/components';
import { formatValue } from '@shared/currency-helpers';
import { ConditionalWrapper, parseISO8601DurationToMonths } from '@shared/helpers';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';
import { fixFloatingNumber } from '@shared/plans';
import { usePortal } from '@hooks';
import { dealsTheme } from '@theme';

import { AddonChecklist, TEXT_DATA as addonTextData } from '../../add-on/add-on';

const AddonsModal = ({ t, hideModal }) => {
  const textData = addonTextData(t);

  return (
    <Grid.Container
      width={{ _: 328, sm: 528 }}
      py={{ _: 32, sm: 40 }}
      backgroundColor="white"
      borderRadius="md"
    >
      <Grid.Container width={{ _: 280, sm: 368 }} rowGap={24}>
        <Text.Heading3 as="h1" $fontFamily="title">
          {`${t('checkout:addOn.shield.title')} + ${t('checkout:addOn.dataBreachMonitor.title')}`}
        </Text.Heading3>
        <Box width="100%">
          <AddonChecklist data={textData.shield} />
        </Box>
        <Box width="100%">
          <AddonChecklist data={textData.dataBreachMonitor} />
        </Box>
        <Button type="button" size="large" fullWidth onClick={hideModal}>
          {t('common:actions.close')}
        </Button>
      </Grid.Container>
    </Grid.Container>
  );
};

const AddonPrice = styled(Box)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.addonBackground};
    transition: 200ms;
  }
`;

const OrderSummary = ({
  selectedCard: {
    subscriptionPeriod,
    discountAmount,
    basePrice,
    price,
    currency,
    discountPercentage,
    extraTime,
    addon,
  },
  orderData,
  selectedAddon,
  onAddonSelect,
  onAddonRemove,
  t,
}) => {
  const isAddonSelected = !!selectedAddon;
  const { isLoading } = orderData;

  const isAddonFree = addon.discountPercentage === 100;

  const totalMonths =
    parseISO8601DurationToMonths(subscriptionPeriod) +
    (extraTime ? parseISO8601DurationToMonths(extraTime) : 0);
  const totalPricePerMonth = fixFloatingNumber(
    orderData.totalPriceAfterTax / totalMonths,
    currency
  );
  const {
    showModal,
    hideModal,
    Modal: AddonsModalComponent,
  } = usePortal({
    container: Modal.Container,
    content: AddonsModal,
  });

  return (
    <React.Fragment>
      <AddonsModalComponent t={t} hideModal={hideModal} />
      <Grid.Container mt={24} mb={8}>
        <Grid.Row
          px={{ _: 8, md: 16 }}
          mb={4}
          justifyContent="space-between"
          flexWrap="nowrap"
          alignItems="center"
          columnGap={16}
        >
          <Text.Body3 data-testid="order-summary-subscription-period">
            <Text.Body3 as="span">
              {t(`plans:checkoutCard.periodTitle.${subscriptionPeriod}`)}
            </Text.Body3>
            {!!extraTime && (
              <Text.Body3 as="span" whiteSpace="nowrap">
                {' '}
                <Trans i18nKey={`plans:monthsExtra.${extraTime}`} components={[<span key={0} />]} />
              </Text.Body3>
            )}
          </Text.Body3>
          <Text.Body3 whiteSpace="nowrap">
            {formatValue(discountAmount ? basePrice : price, currency)}
          </Text.Body3>
        </Grid.Row>
        {discountAmount && (
          <Grid.Row
            mt={8}
            px={{ _: 8, md: 16 }}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            columnGap={4}
          >
            <Text.Body3Strong color="danger">
              {t('checkout:paymentSummary.planDiscount', {
                discountPercentage,
              })}
            </Text.Body3Strong>
            <Text.Body3 color="danger" whiteSpace="nowrap">
              - {formatValue(discountAmount, currency)}
            </Text.Body3>
          </Grid.Row>
        )}
        {isAddonSelected && <Divider my={8} backgroundColor="primary_20" height={1} />}
        <Grid.Row
          mt={isAddonSelected ? 0 : 8}
          px={{ _: 8, md: 16 }}
          justifyContent="space-between"
          alignItems={isAddonSelected ? 'center' : 'stretch'}
          flexWrap="nowrap"
          columnGap={4}
          py={!isAddonSelected && 8}
          backgroundColor={!isAddonSelected && 'addonBackground'}
          borderRadius="sm"
        >
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Text.Body3>{`${t('checkout:addOn.shield.title')} + ${t(
              'checkout:addOn.dataBreachMonitor.title'
            )}`}</Text.Body3>
            {!isAddonSelected && (
              <React.Fragment>
                <Text.Body4 $lineHeight="normal">{t('checkout:header.addOn.title')}</Text.Body4>
                <Box mt={16} display="flex">
                  <Button type="button" variant="text" onClick={showModal}>
                    <Text.Body4Strong color="inherit">
                      {t('common:links.learnMore')}
                    </Text.Body4Strong>
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
          {isLoading ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box width={100} height={28} display="flex" alignItems="center">
                <Text.Body3 isLoading />
              </Box>
              {!isAddonSelected && (
                <Box width={100} height={28} display="flex" alignItems="center">
                  <Text.Body3 isLoading />
                </Box>
              )}
            </Box>
          ) : isAddonSelected ? (
            <AddonPrice
              px={{ _: 8, md: 16 }}
              py={4}
              borderRadius="sm"
              display="flex"
              alignItems="center"
              columnGap={8}
              position="relative"
              left={{ _: 13, md: 21 }}
            >
              <Text.Body3 whiteSpace="nowrap">
                {formatValue(
                  isAddonFree ? selectedAddon?.regularPrice : selectedAddon?.price,
                  selectedAddon?.currency
                )}
              </Text.Body3>
              <Button
                type="button"
                variant="text"
                onClick={() => {
                  onAddonRemove();
                  trackCustomGA4Event({ eventName: GA4_EVENTS.orderAddonRemoveClick });
                }}
              >
                <Icon.Close size={20} color="darkBlue" />
              </Button>
            </AddonPrice>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box>
                {isAddonFree && (
                  <Text.Body3Strong mb={4} whiteSpace="nowrap" color="darkBlue_40" textAlign="end">
                    <CrossedSpan>
                      {t('common:priceTexts.pricePerMonth', {
                        currency: addon?.currency,
                        pricePerMonth: addon?.regularPricePerMonth,
                      })}
                    </CrossedSpan>
                  </Text.Body3Strong>
                )}
                <Text.Body3Strong mb={8} whiteSpace="nowrap">
                  {t('common:priceTexts.pricePerMonth', {
                    currency: addon?.currency,
                    pricePerMonth: addon?.pricePerMonth,
                  })}
                </Text.Body3Strong>
              </Box>
              <ConditionalWrapper
                condition={isAddonFree}
                wrapper={(children) => <ThemeProvider theme={dealsTheme}>{children}</ThemeProvider>}
              >
                <Button
                  type="button"
                  size="small"
                  onClick={() => {
                    onAddonSelect();
                    trackCustomGA4Event({ eventName: GA4_EVENTS.orderAddonAddClick });
                  }}
                >
                  {isAddonFree
                    ? t('checkout:paymentSummary.addForFree')
                    : t('checkout:paymentSummary.add')}
                </Button>
              </ConditionalWrapper>
            </Box>
          )}
        </Grid.Row>
        {isAddonSelected && isAddonFree && (
          <Grid.Row
            mt={8}
            px={{ _: 8, md: 16 }}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            columnGap={4}
          >
            {isLoading ? (
              <React.Fragment>
                <Box width={100} height={20} display="flex" alignItems="center">
                  <Text.Body3 isLoading />
                </Box>
                <Box width={100} height={20} display="flex" alignItems="center">
                  <Text.Body3 isLoading />
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Text.Body3Strong color="danger" isLoading={isLoading}>
                  {t('checkout:paymentSummary.planDiscount', {
                    discountPercentage: selectedAddon.discountPercentage,
                  })}
                </Text.Body3Strong>
                <Text.Body3 color="danger" whiteSpace="nowrap" isLoading={isLoading}>
                  - {formatValue(addon.discountAmount, currency)}
                </Text.Body3>
              </React.Fragment>
            )}
          </Grid.Row>
        )}
      </Grid.Container>
      <Divider my={8} backgroundColor="primary_20" height={1} />
      <Grid.Container>
        <Grid.Row
          px={{ _: 8, md: 16 }}
          justifyContent="space-between"
          alignItems="flex-start"
          columnGap={4}
        >
          <Box>
            <Text.Body2Strong $lineHeight="32px">
              {t('checkout:paymentSummary.orderTotal')}
            </Text.Body2Strong>
          </Box>
          <Box minWidth="30%">
            <Text.Body2Strong
              $lineHeight="32px"
              data-testid={'total-summary'}
              isLoading={isLoading}
              textAlign="end"
            >
              {formatValue(orderData.totalPriceAfterTax, orderData.currency?.toUpperCase())}
            </Text.Body2Strong>
          </Box>
        </Grid.Row>
        <Grid.Row px={{ _: 8, md: 16 }} justifyContent="end">
          <Box minWidth="30%">
            <Text.Body3 textAlign="end" isLoading={isLoading}>
              {formatValue(totalPricePerMonth, orderData.currency?.toUpperCase())}
              {t('plans:checkoutCard.perMonthShort')}
            </Text.Body3>
          </Box>
        </Grid.Row>
      </Grid.Container>
    </React.Fragment>
  );
};

export default OrderSummary;
