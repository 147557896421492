import styled from 'styled-components';

export const GooglePayButtonPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  height: 58px;
  min-height: 58px;
  margin-bottom: 8px;
  background-color: #000;
  box-shadow: none;
`;

export const GooglePayButtonWrapper = styled.div`
  width: 100%;
  height: 100%;

  .google-pay-button-container {
    display: block;
    height: 100%;

    > div {
      height: 100%;

      button {
        border-radius: 4px;
        border: 0;
        height: 100%;
        width: 100%;

        .gpay-card-info-animation-container {
          border-radius: 4px;

          // following are for centering loading placeholder, so layout doesn't shift that much when loaded
          .gpay-card-info-placeholder-container {
            align-items: center;
            padding-bottom: 5px;

            .gpay-card-info-animation-gpay-logo {
              margin-top: 6px;
            }
          }
        }

        .gpay-card-info-iframe {
          padding-top: 5px;
        }
      }
    }
  }
`;
