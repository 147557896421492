import { PAYMENT_METHODS, STRIPE_PAYMENT_TYPES } from '@shared/constants/checkout';
import { CardElement, IdealBankElement } from '@stripe/react-stripe-js';

import { executePayment } from './payment-utils';

export const handleSubmit = async ({
  coupon,
  elements,
  executeRecaptcha,
  scrollToEmailStep,
  selectedCard,
  totalPrice,
  selectedPaymentMethod,
  setPaymentMethodError,
  setHasFailed,
  setIsCompleted,
  setIsLoading,
  setTouched,
  stripe,
  t,
  trackSubmitClick,
  validateForm,
  values,
  language,
}) => {
  setIsLoading(true);

  const formErrors = await validateForm();
  setTouched({ email: true, name: true });

  const paymentMethodData = {
    CARD_PAYMENT: { card: elements.getElement(CardElement) },
    IDEAL: {
      ideal: elements.getElement(IdealBankElement),
      billing_details: { email: values.email },
    },
    KLARNA: { billing_details: { address: { country: 'US' }, email: values.email } },
    SOFORT: {
      sofort: {
        country: 'DE',
      },
      billing_details: {
        email: values.email,
      },
    },
  };

  let error;
  let paymentMethod;
  ({ error, paymentMethod } = await stripe.createPaymentMethod({
    type: STRIPE_PAYMENT_TYPES[selectedPaymentMethod],
    ...paymentMethodData[selectedPaymentMethod],
  }));

  if (selectedPaymentMethod === PAYMENT_METHODS.IDEAL && !error && !paymentMethod?.ideal.bank) {
    // Stripe does not return an error here even if the input is empty so manually setting error
    error = { bank: t('checkout:errors.selectBank') };
  }

  if (!error && !Object.keys(formErrors).length) {
    trackSubmitClick();

    setPaymentMethodError({});

    executePayment({
      paymentMethod,
      formValues: values,
      executeRecaptcha,
      t,
      selectedCard,
      totalPrice,
      coupon,
      stripe,
      selectedPaymentMethod,
      setIsCompleted,
      setHasFailed,
      setIsLoading,
      language,
    });
  } else {
    setIsLoading(false);

    error && setPaymentMethodError(error);

    const errorPayload = {
      CARD_PAYMENT: { ...formErrors, ...(error && { cardNumber: error.message }) },
      IDEAL: {
        ...formErrors,
        ...(error && { idealError: error.message || error.bank }),
      },
      KLARNA: { ...formErrors, ...(error && { klarnaError: error.message }) },
      SOFORT: { ...formErrors, ...(error && { sofortError: error.message }) },
    };

    trackSubmitClick(errorPayload[selectedPaymentMethod]);

    if (formErrors.email) {
      scrollToEmailStep();
    }
  }
};

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i;

const validate = ({ values, t, selectedPaymentMethod }) => {
  const errors = {};

  // name validation only required when submitting card payment
  if (selectedPaymentMethod === PAYMENT_METHODS.CARD_PAYMENT) {
    if (!values.name) {
      errors.name = t('checkout:errors.cardNameBlank');
    }
  }

  if (!values.email) {
    errors.email = t('checkout:errors.emailBlank');
  } else if (!emailRegex.test(values.email)) {
    errors.email = t('checkout:errors.emailInvalid');
  }

  return errors;
};

export const checkoutFormController = ({ t, email, selectedPaymentMethod }) => ({
  initialValues: {
    name: '',
    email: email || '',
  },
  validate: (values) => validate({ values, t, selectedPaymentMethod }),
  onSubmit: () => null,
});

export const hasError = (formik, name) => formik.touched[name] && formik.errors[name];
