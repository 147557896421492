import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Trans } from 'react-i18next';

import { Box, Button } from '@core';
import { ImageCore, Text } from '@components';
import { CURRENCIES } from '@shared/constants/currencies';
import { ErrorText } from '@containers/checkout/components';
import { theme } from '@theme';
import applePayIcon from '@images/checkout/apple-pay-method-white.svg';

import MethodContainer from '../../method-container';
import Summary from '../components/summary';

const ApplePayButtonImage = () => (
  <ImageCore mx={8} src={applePayIcon} alt="apple pay" height={22} />
);

const formatAmountForPaymentMethod = (number, currency) => {
  const NON_DECIMAL_CURRENCIES = [CURRENCIES.KRW.code, CURRENCIES.JPY.code];

  const fixedNumber = NON_DECIMAL_CURRENCIES.includes(currency.toUpperCase())
    ? number
    : (number * 100).toFixed();

  return Number(fixedNumber);
};

const ApplePay = ({
  methodContainerProps,
  stripe,
  selectedCard,
  orderData,
  executePayment,
  email,
  emailError,
  triggerEmailError,
  isLoading: isCheckoutLoading,
  trackSubmitClick,
}) => {
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (stripe && !paymentRequest && orderData.currency && orderData.totalPriceAfterTax) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: orderData.currency.toLowerCase(),
        total: {
          label: 'Total',
          amount: formatAmountForPaymentMethod(orderData.totalPriceAfterTax, orderData.currency),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result?.applePay && !paymentRequest) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, orderData.totalPriceAfterTax, orderData.currency, paymentRequest]);

  useEffect(() => {
    // updating payment interface listener when executePayment changes
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', async (event) => {
        setIsLoading(true);
        await executePayment(event.paymentMethod);
        event.complete('success');
      });
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off('paymentmethod');
      }
    };
  }, [executePayment, paymentRequest]);

  // payment method shown when paymentRequest is available and currency is not IDR (payment modal doesn't open with IDR for unknown reason)
  return paymentRequest && selectedCard.currency !== CURRENCIES.IDR.code ? (
    <MethodContainer {...methodContainerProps}>
      <Summary selectedCard={selectedCard}>
        <Box mt={24} mb={16}>
          <ThemeProvider
            theme={{
              buttons: {
                ...theme.buttons,
                default: {
                  primary: {
                    color: theme.colors.white,
                    default: '#000000',
                    hover: '#3D4043',
                    active: '#000000',
                    disabled: '#3D4043',
                  },
                },
              },
            }}
          >
            <Button
              // all these loading states are relevant to prevent opening payment modal until all needed data is loaded for payment modal
              disabled={isLoading || isCheckoutLoading || orderData.isLoading}
              fullWidth
              type="button"
              size="large"
              onClick={() => {
                if (!email || emailError) {
                  triggerEmailError();
                } else {
                  // updating paymentRequest before payment modal shows up, so right currency and amount is shown for user in the payment interface
                  paymentRequest.update({
                    currency: orderData.currency.toLowerCase(),
                    total: {
                      label: 'Total',
                      amount: formatAmountForPaymentMethod(
                        orderData.totalPriceAfterTax,
                        orderData.currency
                      ),
                    },
                  });

                  trackSubmitClick();

                  // opening payment modal
                  paymentRequest.show();
                }
              }}
            >
              <Text.Body1Strong as="span" color="white" lineHeight="22px">
                <Box
                  as="span"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="wrap"
                >
                  <Trans
                    i18nKey="checkout:buyWithPaymentMethod"
                    components={[<ApplePayButtonImage key={0} />]}
                  />
                </Box>
              </Text.Body1Strong>
            </Button>
          </ThemeProvider>
          {emailError && <ErrorText>{emailError}</ErrorText>}
        </Box>
      </Summary>
    </MethodContainer>
  ) : null;
};

export default ApplePay;
