import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button } from '@core';
import { ImageCore, LinkWrapper, Text } from '@components';
import checkMarkInCircle from '@images/checkout/check-mark-in-circle.svg';
import { ONE_MONTH_PLAN } from '@shared/constants/checkout';

const TextLinkWrapper = ({ children, link, target, rel }) => (
  <LinkWrapper link={link} target={target} rel={rel}>
    <Button type="button" variant="text">
      <Text.Body4 $fontSize={10} as="span" color="inherit">
        {children}
      </Text.Body4>
    </Button>
  </LinkWrapper>
);

const Summary = ({ children, dataTestId, selectedCard }) => {
  const { t } = useTranslation();

  return (
    <Box data-testid={dataTestId}>
      <Text.Body4 mt={32} mb={16} $fontSize={10}>
        <Trans
          i18nKey="checkout:paymentSummary.termsComment"
          components={[
            <TextLinkWrapper
              key={0}
              link="https://atlasvpn.com/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            />,
            <TextLinkWrapper
              key={1}
              link="https://atlasvpn.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            />,
          ]}
        />
      </Text.Body4>
      {children}
      <Box
        mb={24}
        mt={16}
        display="flex"
        alignItems={{ _: 'flex-start', sm: 'center' }}
        justifyContent="center"
        columnGap={16}
      >
        <ImageCore src={checkMarkInCircle} alt="checkmark" />
        {selectedCard.subscriptionPeriod === ONE_MONTH_PLAN ? (
          <Text.Body2 lineHeight="20px">{t('checkout:paymentSummary.safeShopping14')}</Text.Body2>
        ) : (
          <Text.Body2 lineHeight="20px">{t('checkout:paymentSummary.safeShopping')}</Text.Body2>
        )}
      </Box>
    </Box>
  );
};

export default Summary;
