import { loadScript } from '@paypal/paypal-js';

import { paypalOnConfirmSubscription } from '@api/checkout';

import {
  createPurchaseEventData,
  createPurchaseEventItem,
  firePurchaseEvent,
} from '@containers/checkout/utils/tracking';
import { getTrackingParamsForPayment } from '@containers/checkout/utils/local-storage';
import { paymentErrorHandler } from '@shared/checkout-error-helpers';
import { LANGUAGES } from '@shared/constants/languages';
import { getUUID } from '@shared/local-storage-tracking';
import { updateOrderAndPay } from '@api/order';

const PAYMENT_TYPE = 'PAYPAL';
const ONE_TIME_TYPE = 'one-time';
const COMMON_OPTIONS = {
  'client-id': process.env.PAYPAL_CLIENT_ID,
};

const SUBSCRIPTION_OPTIONS = {
  ...COMMON_OPTIONS,
  intent: 'subscription',
  vault: 'true',
};

export const getScriptOptions = (planPaymentType, currency, language) => ({
  currency: currency,
  ...(planPaymentType === ONE_TIME_TYPE ? COMMON_OPTIONS : SUBSCRIPTION_OPTIONS),
  locale: LANGUAGES.find((languageObject) => languageObject.value === language).locale,
});

export const getButtonOptions = (
  planPaymentType,
  { emailRef, planRef, approveCallbackRef, errorCallbackRef, validateEmailRef, isEmailValidRef },
  t
) => {
  const commonButtonOptions = {
    onError: (error) => {
      paymentErrorHandler({
        error,
        commonErrorHandler: errorCallbackRef.current,
        t,
      });
    },
    onClick: (data, actions) => {
      validateEmailRef.current();

      if (!isEmailValidRef.current) {
        return actions.reject();
      }
      return actions.resolve();
    },
    onCancel: () => {
      errorCallbackRef.current();
    },
  };

  const price = planRef.current.price;

  const purchaseEventItem = createPurchaseEventItem(
    planRef.current.identifier,
    planRef.current.title,
    1,
    price
  );

  let subscriptionToken;

  const ordersPayload = { uniqueId: getUUID(), metadata: getTrackingParamsForPayment() };

  return planPaymentType === ONE_TIME_TYPE
    ? {
        ...commonButtonOptions,
        style: {
          label: 'pay',
          height: 55,
        },
        createOrder: () => {
          return updateOrderAndPay({ ...ordersPayload }).then(({ orderID }) => orderID);
        },
        onApprove: ({ orderID }) => {
          return updateOrderAndPay({
            ...ordersPayload,
            metadata: { ...ordersPayload.metadata, order_id: orderID },
          }).then(() => {
            const purchaseEvent = createPurchaseEventData(
              orderID,
              purchaseEventItem.price,
              planRef.current.currency,
              PAYMENT_TYPE,
              purchaseEventItem
            );

            firePurchaseEvent(purchaseEvent);
            approveCallbackRef.current();
          });
        },
      }
    : {
        ...commonButtonOptions,
        style: {
          label: 'subscribe',
          height: 55,
        },
        createSubscription: () => {
          return updateOrderAndPay({ ...ordersPayload }).then(({ token, subscriptionID }) => {
            subscriptionToken = token;
            return subscriptionID;
          });
        },
        onApprove: (data) => {
          const payload = { token: subscriptionToken };

          return paypalOnConfirmSubscription(payload, () => {
            const purchaseEvent = createPurchaseEventData(
              data.orderID,
              purchaseEventItem.price,
              planRef.current.currency,
              PAYMENT_TYPE,
              purchaseEventItem
            );

            firePurchaseEvent(purchaseEvent);
            approveCallbackRef.current();
          });
        },
      };
};

export const loadPaypal = ({
  buttonsContainerId,
  scriptOptions,
  buttonOptions,
  buttonsRef,
  onLoadStart,
  onLoadScript,
  onLoadError,
}) => {
  onLoadStart();

  loadScript(scriptOptions)
    .then((paypal) => {
      onLoadScript();

      buttonsRef.current = paypal.Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        ...buttonOptions,
      });
      buttonsRef.current.render(`#${buttonsContainerId}`).catch((error) => {
        console.warn('Warning - Caught an error when attempting to render component', error);
      });
    })
    .catch(() => {
      onLoadError();
    });
};
