export const cardOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#4c8ff9',
      color: '#000000',
      fontWeight: 500,
      fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#b3b3c9' },
    },
    invalid: {
      iconColor: '#ff6554',
      color: '#ff6554',
    },
  },
};
