import { Collapse } from 'react-collapse';
import React from 'react';
import styled from 'styled-components';

import { Box, Grid } from '@core';
import { Animation, Icon, ImageCore, Text } from '@components';

const CollapsableContainer = styled.div`
  width: 100%;

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

const MethodContainer = ({ name, logo, onClick, isSelected, children, dataTestId }) => (
  <Grid.Container
    my={24}
    px={{ _: 16, md: 40 }}
    pt={12}
    pb={8}
    border="2px solid"
    borderColor={isSelected ? 'primary' : 'primary_20'}
    borderRadius={isSelected ? 'md' : 'sm'}
  >
    <Grid.Row onClick={onClick} alignItems="center" data-section-id={`${name} method`}>
      <Grid.Col size={11}>
        <Box alignItems="center" justifyContent="space-between" data-testid={dataTestId}>
          <Text.Body2Strong mb={8}>{name}</Text.Body2Strong>
          <ImageCore src={logo.src} alt={logo.alt} />
        </Box>
      </Grid.Col>
      <Grid.Col display="flex" justifyContent="flex-end" size={1}>
        <Animation.Rotate rotate={isSelected}>
          <Icon.Down size={24} />
        </Animation.Rotate>
      </Grid.Col>
    </Grid.Row>

    <CollapsableContainer>
      <Collapse isOpened={isSelected}>
        <Box mt={24}>{isSelected && children}</Box>
      </Collapse>
    </CollapsableContainer>
  </Grid.Container>
);

export default MethodContainer;
