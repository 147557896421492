// TODO: rewrite to typescript for typechecking
export const firePurchaseEvent = (data) => {
  if (typeof window !== 'undefined') {
    const { transaction_id, value, currency, items } = data;

    if (window.gtag) {
      window.gtag('event', 'purchase', { ...data });

      window.gtag('event', 'conversion', {
        send_to: `${process.env.GOOGLE_ADS_ID}/B-OrCKWn_v8BEMf0ocwC`,
        transaction_id,
        value,
        currency,
      });
    }

    if (window.uetq) {
      window.uetq.push('event', 'purchase', {
        transaction_id,
        revenue_value: value,
        currency,
        items,
      });
    }
  }
};

export const createPurchaseEventData = (transaction_id, value, currency, affiliation, item) => ({
  transaction_id,
  value,
  currency,
  affiliation,
  items: [item],
});

export const createPurchaseEventItem = (id, name, quantity, price) => ({
  id,
  name,
  quantity,
  price,
});
