import React from 'react';
import styled from 'styled-components';
import { IdealBankElement } from '@stripe/react-stripe-js';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { Box, Button } from '@core';
import { Text } from '@components';
import { ErrorText } from '@containers/checkout/components';
import { iDealTheme, theme } from '@theme';

import Summary from '../components/summary';

const IDealInputWrapper = styled.div`
  border: ${({ error, theme }) =>
    `solid 1px ${error ? theme.colors.danger : theme.colors.darkBlue_40}`};
  border-radius: 4px;
  height: 40px;
  width: 100%;

  &:hover {
    border: ${({ error, theme }) =>
      `solid 1px ${error ? theme.colors.danger : theme.colors.primary}`};
  }
`;

const IDEAL_ELEMENT_OPTIONS = {
  style: {
    base: {
      padding: '12px 12px',
      color: theme.colors.darkBlue,
      fontWeight: 500,
      fontFamily: theme.fonts.default,
      fontSize: '14px',
      fontSmoothing: 'antialiased',
    },
  },
};

const IdealPayment = ({
  selectedCard,
  stripe,
  isLoading,
  iDealElementError,
  emailError,
  setIDealElementError,
  submitHandler,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box mt={24} mb={16}>
        <Text.Body4 mb={8}>{t('checkout:inputFields.bankName.label')}</Text.Body4>
        <IDealInputWrapper error={iDealElementError?.bank}>
          <IdealBankElement
            onChange={() => setIDealElementError({})}
            options={IDEAL_ELEMENT_OPTIONS}
          />
        </IDealInputWrapper>
        {iDealElementError.bank && <ErrorText>{iDealElementError.bank}</ErrorText>}
      </Box>
      <Summary selectedCard={selectedCard}>
        <Box mt={24} mb={16}>
          <ThemeProvider theme={iDealTheme}>
            <Button
              fullWidth={true}
              type="button"
              onClick={submitHandler}
              size="large"
              disabled={!stripe || isLoading || emailError || iDealElementError.bank}
            >
              {isLoading ? (
                t('checkout:cardPayment.processing')
              ) : (
                <Trans
                  i18nKey="checkout:buyWithPaymentMethod"
                  values={{ paymentMethod: t('checkout:paymentMethods.iDeal') }}
                />
              )}
            </Button>
          </ThemeProvider>
          {(emailError || iDealElementError.message) && (
            <ErrorText>{emailError || iDealElementError.message}</ErrorText>
          )}
        </Box>
      </Summary>
    </React.Fragment>
  );
};

export default IdealPayment;
