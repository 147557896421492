import { getItemsFromLocalStorage } from '@shared/local-storage-tracking';

export const getTrackingParamsForPayment = () => {
  const {
    offer_id,
    transaction_id,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    aff_sub,
  } = getItemsFromLocalStorage();

  return {
    offer_id: offer_id && Number(offer_id),
    transaction_id,
    utm_source: utm_source && `${utm_source}`,
    utm_medium: utm_medium && `${utm_medium}`,
    utm_campaign: utm_campaign && `${utm_campaign}`,
    utm_term: utm_term && `${utm_term}`,
    utm_content: utm_content && `${utm_content}`,
    aff_sub: aff_sub && `${aff_sub}`,
    document_referrer: document.referrer || undefined,
    // TODO add when available
    // goal_name,
    // advertiser_id,
  };
};
