import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@core';
import { Text } from '@components';
import { ErrorText } from '@containers/checkout/components';
import { getTrackingParamsForPayment } from '@containers/checkout/utils/local-storage';
import { getUUID, removeUUID } from '@shared/local-storage-tracking';
import { updateOrderAndPay } from '@api/order';

import Summary from '../components/summary';

const CryptoPayment = ({
  selectedCard,
  isLoading: isCheckoutLoading,
  triggerEmailError,
  emailError,
  email,
  onErrorCallback,
  successPageSlug,
  errorPageSlug,
  trackSubmitClick,
}) => {
  const [isLoading, setIsLoading] = useState(isCheckoutLoading);

  const { t } = useTranslation();

  const handleCheckoutClick = () => {
    if (!email || emailError) {
      triggerEmailError();
    } else {
      setIsLoading(true);
      updateOrderAndPay({
        uniqueId: getUUID(),
        metadata: {
          ...getTrackingParamsForPayment(),
          success_url: `${window.location.origin}${successPageSlug}`,
          failure_url: `${window.location.origin}${errorPageSlug}`,
        },
      })
        .then((data) => {
          if (!data.redirect_url) {
            onErrorCallback();
            return setIsLoading(false);
          }
          // Clearing uuid before redirecting to 3rd party happens. To protect offer from mutations after 3rd party verification is done.
          removeUUID();
          trackSubmitClick();
          window.location.href = data.redirect_url;
        })
        .catch((error) => {
          trackSubmitClick(error?.response?.data?.error?.message);
          onErrorCallback();
          setIsLoading(false);
        });
    }
  };

  return (
    <Summary selectedCard={selectedCard}>
      <Box mt={24} mb={16}>
        <Button
          disabled={isLoading || emailError}
          fullWidth
          type="button"
          size="large"
          onClick={handleCheckoutClick}
        >
          <Text.Body1Strong
            as="span"
            color="white"
            fontSize={{ _: 16, md: 18 }}
            lineHeight="normal"
          >
            {t('checkout:cryptoPayment.continueToPayment')}
          </Text.Body1Strong>
        </Button>
        {emailError && <ErrorText>{emailError}</ErrorText>}
      </Box>
    </Summary>
  );
};

export default CryptoPayment;
