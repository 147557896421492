import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@components';
import { useDebounce } from '@hooks';
import { ErrorText } from '@containers/checkout/components';

import { getButtonOptions, getScriptOptions, loadPaypal } from './paypal.utils';
import Summary from '../components/summary';

const Paypal = ({
  buttonsContainerId,
  isLoading,
  isEmailValid,
  enteredEmail,
  emailError,
  triggerEmailError,
  selectedCard,
  onApproveCallback,
  onErrorCallback,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { debounceCallback } = useDebounce();

  const [currentScript, setCurrentScript] = useState(null);
  const [loadedScript, setLoadedScript] = useState(null);
  const [isLoadingScript, setIsLoadingScript] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const buttonsRef = useRef();
  const planRef = useRef(selectedCard);
  const validateEmailRef = useRef(triggerEmailError);
  const emailRef = useRef(enteredEmail);
  const isEmailValidRef = useRef(isEmailValid);
  const approveCallbackRef = useRef(onApproveCallback);
  const errorCallbackRef = useRef(onErrorCallback);

  useEffect(() => {
    if (selectedCard) {
      planRef.current = selectedCard;
    }
  }, [selectedCard]);

  useEffect(() => {
    isEmailValidRef.current = isEmailValid;
  }, [isEmailValid]);

  useEffect(() => {
    emailRef.current = enteredEmail;
  }, [enteredEmail]);

  useEffect(() => {
    if (!isLoading && !isLoadingScript) {
      const selectedRecurrenceType = selectedCard.recurrenceType;
      const paypalOptions = {
        buttonsContainerId,
        buttonsRef,
        scriptOptions: getScriptOptions(selectedRecurrenceType, planRef.current.currency, language),
        buttonOptions: getButtonOptions(
          selectedRecurrenceType,
          {
            emailRef,
            planRef,
            approveCallbackRef,
            errorCallbackRef,
            validateEmailRef,
            isEmailValidRef,
          },
          t
        ),
        onLoadStart: () => {
          setIsLoadingScript(true);
        },
        onLoadScript: () => {
          setShowLoader(false);
          setIsLoadingScript(false);
          setCurrentScript({
            recurrenceType: selectedRecurrenceType,
            currency: planRef.current.currency,
          });
        },
        onLoadError: () => {
          setShowLoader(false);
          setIsLoadingScript(false);
        },
      };

      if (
        // Flow for initial loading paypal sdk
        (!currentScript && !loadedScript) ||
        // Flow for paypal sdk reload, when the payment type changes
        (currentScript?.recurrenceType &&
          loadedScript?.recurrenceType !== selectedRecurrenceType) ||
        // Flow for paypal sdk reload, when currency changes
        (currentScript?.currency && loadedScript?.currency !== planRef.current.currency)
      ) {
        setShowLoader(true);
        // Removed already existing buttons to prevent continuing. while loading new script
        buttonsRef.current && buttonsRef.current.close();

        setLoadedScript({
          recurrenceType: selectedRecurrenceType,
          currency: planRef.current.currency,
        });
        debounceCallback(() => {
          loadPaypal(paypalOptions);
        });
      }
    }
  }, [
    buttonsContainerId,
    language,
    t,
    isLoading,
    isLoadingScript,
    selectedCard,
    currentScript,
    loadedScript,
    debounceCallback,
  ]);

  return (
    <Summary selectedCard={selectedCard}>
      {/* Styles required to overwrite paypal default z-index: 100 */}
      <div
        id={buttonsContainerId}
        style={{
          zIndex: 1,
          position: 'relative',
        }}
      >
        {(isLoading || showLoader) && <Loader isLoading={true} />}
      </div>
      {emailError && <ErrorText>{emailError}</ErrorText>}
    </Summary>
  );
};

export default Paypal;
